.hero__container {
  height: 100lvh;
  position: relative;
  padding: 0 var(--page-padding-horizontal);
}

.hero__container > .hero__background__image__container {
  pointer-events: none;
}

.hero__container[id]:not(#home) {
  min-height: calc(100lvh - var(--nav-height));
  height: 100%;
}
