.hero__container#home {
  margin-top: calc(-1 * (var(--nav-height) + var(--nav-margin-top)));
  padding-top: calc(var(--nav-height) + var(--nav-margin-top));
  background-color: #282525;
  color: white;
  -webkit-box-shadow: 0px 2px 5px -1px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 2px 5px -1px rgba(0,0,0,0.75);
  box-shadow: 0px 2px 5px -1px rgba(0,0,0,0.75);
}


.hero__container#home > .hero__background__image__container {
  position: absolute;
  inset: 0;
  background-image: url('../../images/mini-excavator.avif');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  opacity: 50%;
}

@media only screen and (max-width: 1060px) {
  .hero__container#home > .hero__background__image__container {
    background-position: 60% center;
  }
}

.home__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: translateY(-10vh);
  height: 100%;
  width: 100%;
}

.home__content > h1 {
  font-size: 8rem;
  line-height: 100%;
  padding-top: 5%;
  animation-name: home__content__animation__h1;
  animation-duration: 1000ms;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-fill-mode: forwards;
  font-weight: 700;
  font-style: italic;
}

.home__content > h3 {
  font-size: 2rem;
  padding-top: 3%;
  opacity: 80%;
  animation-name: home__content__animation__h3;
  animation-duration: 1000ms;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-fill-mode: forwards;
}

@media only screen and (max-width: 1060px) {
  .home__content > h1 {
    font-size: 5rem;
    font-weight: 500;
    animation: none;
  }
  .home__content > h3 {
    font-weight: 400;
    animation: none;
  }
}

@media only screen and (max-width: 375px) {
  .home__content > h1 {
    font-size: 3rem;
  }
  .home__content > h3 {
    font-size: 1.25rem;
  }
}

@keyframes home__content__animation__h1 {
  from {
    opacity: 0;
    transform: translateX(-20%);
  }
  to {
    opacity: 100%;
    transform: translateX(0%);
  }
}


@keyframes home__content__animation__h3 {
  from {
    opacity: 0;
    transform: translateX(20%);
  }
  to {
    opacity: 80%;
    transform: translateX(0%);
  }
}
