:root {
  --clr-bg: #D9D9D9;
  --clr-thm-1: #FFC700;
  --clr-thm-2: #282525;

  --dropmenu-animation-duration: 200ms; /* also change in Navbar component  */
  --nav-height: 4rem;
  --nav-margin-top: 5%;
  --page-padding-horizontal: 17%;
}

@media only screen and (max-width: 1060px) {
  :root {
    --page-padding-horizontal: 14%;
  }
}

html {
  box-sizing: border-box;
  font-size: 100%;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  background: var(--clr-bg);
}

*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  font-family: 'Outfit', sans-serif;
  -webkit-tap-highlight-color: transparent;
}

a, button {
  text-decoration: none;
  cursor: pointer;
  font-family: 'Outfit', sans-serif;
}

a:link {
  color: var(--clr-thm-2);
}

img {
  display: block;
  max-width: 100%;
}

menu:not(article menu),
ol:not(article ol),
ul:not(article ul) {
  list-style: none;
}

article ol,
article ul {
    list-style-position: inside;
}

.disable__scroll {
  touch-action: none;
  overflow: hidden;
}


@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

