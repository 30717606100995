.secondary__button__component {
  position: relative;
  display: flex;
  width: auto;
  height: auto;
  border: 0.2565em solid var(--clr-thm-1);
  background-color: var(--clr-bg);
  color: var(--clr-thm-2);
  padding: 0.5em;
  outline: none;
  border-radius: 0;
  font-size: 1.0275rem;
  z-index: 0;
}

.secondary__button__component::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 0%;
  bottom: 0;
  left: 0;
  background-color: var(--clr-thm-1);
  transition: 300ms ease;
  z-index: -1;
}

@media only screen and (min-width: 1061px) {
  .secondary__button__component:hover::before {
    height: 100%;
  }
}

@media only screen and (max-width: 1060px) {
  .secondary__button__component:focus::before {
    height: 100%;
  }
}
