.blueprint__container {
  position: absolute;
  inset: 0;
  overflow: hidden;
  pointer-events: none;
}

.blueprint__container > * {
  position: absolute;
  height: 70vh;
}

.blueprint__container #blueprint-1 {
  transform: rotate(-60deg) translateX(-20%) translateY(-75%);
}

.blueprint__container #blueprint-2 {
  right: 0;
  transform: rotate(-30deg) translateX(60%) translateY(30%);
}

.about__content {
  color: var(--clr-thm-2);
}

.about__content > button {
  margin-top: 5vh;
}

.about__content > h1 {
  font-size: 3rem;
  padding-top: 5vh;
  text-align: center;
}

.about__content > article {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  margin-top: 10%;
}

.about__subsection {
  width: 40%;
}

.about__subsection > h1 {
  opacity: 80%;
  padding-bottom: 0.5rem;
  font-size: 1.575rem;
}

.about__subsection > p {
  font-size: 1.3625rem;
  line-height: 140%;
}

.about__content > article > hr {
  position: absolute;
  height: 50%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 2px;
  border: none;
  background-color: black;
  border-radius: 100px;
}


@media only screen and (max-width: 1060px) {
  .blueprint__container > * {
    height: 80h;
  }
  .blueprint__container #blueprint-1 {
    right: 0;
    transform: rotate(-30deg) translateX(80%) translateY(15%);
  }
  .blueprint__container #blueprint-2 {
    bottom: 0;
    transform: rotate(160deg) translateX(20%) translateY(-85%);
  }
  .about__content > h1 {
    font-size: 2rem;
  }
  .about__content > article {
    flex-direction: column;
    gap: 2rem;
  }
  .about__subsection {
    width: 100%;
  }
  .about__subsection > h1 {
    font-size: 1.375rem;
  }
  .about__subsection > p {
    font-size: 1.1625rem;
  }
  .about__content > article > hr {
    display: none;
  }
}

@media only screen and (max-width: 376px) {
  .about__content > h1 {
    font-size: 1.5rem;
  }
  .about__subsection > h1 {
    font-size: 1.1rem;
  }
  .about__subsection > p {
    font-size: 0.9765rem;
  }
}
