nav {
  height: var(--nav-height);
  width: 100%;
  background-color: /*#f8983f*/ transparent;
  position: sticky;
  top: 0;
  margin-top: var(--nav-margin-top);
  padding: 0 var(--page-padding-horizontal);
  z-index: 1;
  transition: background-color 100ms ease;
}

.nav__sticky {
  background-color: #f8983f;
}

.nav__content__container {
  display: inline-flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.nav__links__container {
  display: inline-flex;
  gap: 1.5rem;
}

.nav__links__container a {
  position: relative;
  text-transform: capitalize;
  color: white;
}

.nav__links__container a::after {
  position: absolute;
  content: "";
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 1px;
  background-color: white;
  transition-duration: 300ms;
}

.nav__content__container a:hover::after {
  width: 100%;
}

.nav__logo__container {
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  aspect-ratio: 1/1;
  z-index: 1;
}

.nav__logo__container img {
  height: 45%;
  aspect-ratio: auto;
  cursor: pointer;
}

.nav__hamburger {
  display: none;
  position: relative;
  flex-direction: column;
  height: 45%;
  aspect-ratio: 1.25;
  cursor: pointer;
  z-index: 1;
}

.nav__hamburger > * {
  position: absolute;
  width: 100%;
  height: 10%;
  background-color: white;
  border-radius: 10rem;
  transition: var(--dropmenu-animation-duration);
}

.nav__hamburger #bar-1 {
  top: 0;
}
.animate__hamburger#bar-1 {
  top: 50%;
  transform: translateY(-50%);
}
.nav__hamburger #bar-2 {
  top: 50%;
  transform: translateY(-50%);
}
.nav__hamburger #bar-3 {
  bottom: 0;
}
.animate__hamburger#bar-3 {
  bottom: 50%;
  transform: translateY(50%);
}

@media only screen and (max-width: 1060px) {
  .nav__links__container {
    display: none;
  }
  .nav__hamburger {
    display: flex;
  }
  .drop__menu {
    /* 
      position sticky iz .nav overridea position fixed
      pa se treba koristit !important
    */
    position: fixed !important;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: calc(var(--nav-height) + var(--nav-margin-top) + 5rem);
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    overflow: hidden;
  }
  .drop__menu > li > a {
    font-size: 1.5rem;
    font-weight: bold;
  }
  .animate__drop__menu__forward {
    animation-name: drop__menu__animation;
    animation-duration: var(--dropmenu-animation-duration);
    animation-direction: normal;
    animation-fill-mode: forwards;
  }
  .animate__drop__menu__reverse {
    animation-name: drop__menu__animation;
    animation-duration: var(--dropmenu-animation-duration);
    animation-direction: reverse;
    animation-fill-mode: forwards;
  }
  .animate__drop__links__forward {
    animation-name: drop__links__animation;
    animation-duration: var(--dropmenu-animation-duration);
    animation-direction: normal;
    animation-fill-mode: forwards;
  }
  .animate__drop__links__reverse {
    animation-name: drop__links__animation;
    animation-duration: var(--dropmenu-animation-duration);
    animation-direction: reverse;
    animation-fill-mode: forwards;
  }
}

@keyframes drop__menu__animation {
  0% {
    backdrop-filter: blur(0px);
  }
  100% {
    backdrop-filter: blur(30px);
  }
}

@keyframes drop__links__animation {
  0% {
    opacity: 0%;
    left: -100%;
  }
  100% {
    opacity: 100%;
    left: 0;
  }
}
