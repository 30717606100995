.hero__container#usluge {
  display: flex;
  align-items: center;
}

.hero__container#usluge > .isometric__container {
  position: absolute;
  inset: 0;
  overflow: hidden;
  pointer-events: none;
}

.hero__container#usluge > .isometric__container > .isometric__3d {
  position: absolute;
  width: 38vw;
  max-width: 800px;
  top: 55%;
  right: 0;
  transform: translateY(-50%) translateX(30%);
}

.services__grid__container {
  display: grid;
  width: 50vw;
  place-items: center;
  height: 100%;
}

.services__grid {
  display: flex;
  flex-direction: row;
  gap: 0.8575rem;
}

.services__grid > .column {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.8575rem;
  height: 100%;
}

.services__grid > .column > h1 {
  font-size: 1.8645rem;
  color: var(--clr-thm-2);
}

.service__card {
  background-color: rgb(188, 188, 188, 0.5);
  border-radius: 10px;
  padding: 1.2545rem;
  color: var(--clr-thm-2);
}

.service__card > h1 {
  font-size: 1.2565rem;
}

.service__card > p {
  padding-top: 0.5em;
  font-size: 1rem;
}

.services__vertical__text {
  position: absolute;
  left: 2.5rem;
  transform: translateX(-50%) rotate(90deg);
  letter-spacing: 0.1em;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  color: var(--clr-bg);
  font-size: 2.5rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
}

@media only screen and (max-width: 645px) {
  .services__vertical__text {
    display: none;
  }
}

@media only screen and (max-width: 1375px) {
  .services__grid > .column > h1 {
    font-size: 1.5675rem;
  }
  .service__card {
    padding: 0.9575rem;
  }
  .service__card > h1 {
    font-size: 1.1575rem;
  }
  .service__card > p {
    font-size: 0.9065rem;
  }
}

@media only screen and (max-width: 1060px) {
  .hero__container#usluge {
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
    justify-content: center;
  }
  .hero__container#usluge > .isometric__container > .isometric__3d {
    top: 2%;
    right: 0;
    transform: translateX(30%);
    width: auto;
    height: 45vw;
    max-height: 185px;
  }
  .services__grid__container {
    display: flex;
    place-items: initial;
    width: 100%;
    max-width: 500px;
    padding-top: min(12vw, 4.5rem);
  }
  .services__grid {
    flex-direction: column;
  }
  .services__grid > .column {
    display: grid;
    grid-column-gap: 0.7645rem;
    grid-row-gap: 0.7645rem;
    width: 100%;
  }
  .services__grid > .column > h1 {
    font-size: 1.2565rem;
  }
  .services__grid > .column#column-1 {
    grid-template-columns: 60% auto;
    grid-template-rows: repeat(2, auto);
    width: 100%;
  }
  .services__grid > .column#column-1 > #el-1 {
    grid-area: 1 / 1 / 2 / 2;
  }
  .services__grid > .column#column-1 > #el-2 {
    grid-area: 2 / 1 / 3 / 3;
  }
  .services__grid > .column#column-1 > h1 {
    display: flex;
    grid-area: 1 / 2 / 2 / 3;
    align-items: flex-end;
  }
  .services__grid > .column#column-2 {
    grid-template-columns: 45% auto;
    grid-template-rows: repeat(2, auto);
    width: 100%; 
  }
  .services__grid > .column#column-2 > h1 {
    display: flex;
    grid-area: 1 / 1 / 2 / 2;
    align-items: center;
  }
  .services__grid > .column#column-2 > #el-1 {
    grid-area: 1 / 2 / 2 / 3;
  }
  .services__grid > .column#column-2 > #el-1 {
    grid-area: 2 / 1 / 3 / 3;
  }
  .service__card > h1 {
    font-size: 1.1365rem;
  }
  .service__card > p {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 388px) {
  .services__grid > .column > h1 {
    font-size: 1.1565rem;
  }
  .service__card > h1 {
    font-size: 1.0365rem;
  }
  .service__card > p {
    font-size: 0.9065rem;
  }
}

@media only screen and (max-width: 280px) {
  .hero__container#usluge > .isometric__container {
    display: none;
  }
  .services__grid > .column > h1 {
    font-size: 0.9075rem;
  }
  .service__card > h1 {
    font-size: 0.8065rem;
  }
  .service__card > p {
    font-size: 0.7075rem;
  }
}
