.banner {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background-color: var(--clr-thm-1);
  padding: 0.5rem var(--page-padding-horizontal);
  -webkit-box-shadow: 0px 2px 5px -1px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 2px 5px -1px rgba(0,0,0,0.75);
  box-shadow: 0px 2px 5px -1px rgba(0,0,0,0.75);
}

.banner > h1 {
  color: var(--clr-thm-2);
  opacity: 80%;
  font-weight: 700;
}

.banner > h3 {
  color: white;
  font-weight: 500;
}

@media only screen and (max-width: 1060px) {
  .banner > h1 {
    font-size: 1.4275rem;
  }
  .banner > h3 {
    font-size: 1.0567rem;
  }
}

@media only screen and (max-width: 300px) {
  .banner > h3 {
    font-size: 1.1256rem;
  }
}
