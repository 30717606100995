.hero__container#kontakt {
  color: var(--clr-thm-2);
  padding-bottom: 2.5%;
}

.call__to__action {
  width: 100%;
  text-align: center;
  font-size: 2.7565rem;
  padding-top: 0.5em;
}

.call__to__action > #s__nama {
  position: relative;
}

.call__to__action > #s__nama > img {
  position: absolute;
  z-index: -1;
  top: 55%;
  left: 0;
  transform: translateY(-50%) scale(1.4) rotate(-2.5deg);
  height: 4rem;
}

.contact__content {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding-top: 1.7565rem;
}

.mobitel__radno__vrijeme {
  display: flex;
  width: 50%;
  gap: 3.5rem;
  flex-direction: column;
}

.lokacija {
  display: flex;
  width: 50%;
  flex-direction: column;
}

.mobitel__component {
  position: relative;
  width: 100%;
}

.mobitel__component::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: black;
}

.mobitel__component > h1,
.mobitel__component > p {
  font-size: 1.5275rem;
}

.mobitel__component > p {
  position: relative;
  width: fit-content;
  padding: 0.4575em 0;
}

.mobitel__component > p::before {
  content: "";
  position: absolute;
  top: 15%;
  bottom: 15%;
  left: -2.5%;
  right: -2.5%;
  background-color: rgb(188, 188, 188, 0.5);
  z-index: -1;
  border-radius: 5px;
}

.mobitel__component > .phone__icon__container {
  position: absolute;
  right: 0;
  top: 10%;
  width: 3.5rem;
  aspect-ratio: 1/1;
  background-color: var(--clr-thm-1);
  border-radius: 50%;
  padding: 0.7625rem;
}

.mobitel__component > .phone__icon__container > img {
  opacity: 60%;
}

.radno__vrijeme__component {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.625rem;
  width: 100%;
  padding: 3rem 0;
}

.radno__vrijeme__component__before { 
  content: "";
  position: absolute;
  inset: 0;
  background-color: var(--clr-thm-1);
  z-index: -1;
  -webkit-box-shadow: 0px 2px 5px -1px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 2px 5px -1px rgba(0,0,0,0.75);
  box-shadow: 0px 2px 5px -1px rgba(0,0,0,0.75);
}

.radno__vrijeme__component > h1 {
  font-size: 1.5275rem;
}

.radno__vrijeme__component > .info {
  display: grid;
  width: 70%;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 1rem;
  font-size: 1.325rem;
}
  
.radno__vrijeme__component > .info > #dani-1 { grid-area: 1 / 1 / 2 / 2; }
.radno__vrijeme__component > .info > #dani-2 { grid-area: 2 / 1 / 3 / 2; }
.radno__vrijeme__component > .info > #vrijeme-1 { grid-area: 1 / 2 / 2 / 3; }
.radno__vrijeme__component > .info > #vrijeme-2 { grid-area: 2 / 2 / 3 / 3; }

.radno__vrijeme__component > .clock__icon__container {
  position: absolute;
  border-radius: 50%;
  width: 3.5rem;
  aspect-ratio: 1/1;
  padding: 0.7625rem;
  top: 0;
  left: 0;
  transform: translateY(-50%);
  background-color: var(--clr-bg);
}

.radno__vrijeme__component > .clock__icon__container > img {
  opacity: 60%;
}

.rv__before__dots {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0;
  top: 0;
  bottom: 0;
  justify-content: space-evenly;
  padding-right: 1rem;
}

.rv__before__dots > * {
  content: "";
  width: 1.2625rem;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: var(--clr-bg);
}

.lokacija__component {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 1.5rem;
}

.lokacija__component > .info {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-left: 30%;
  gap: 0.3rem;
}

.lokacija__component > .info > h1,
.lokacija__component > .info > p {
  position: relative;
}

.lokacija__component > .info > h1 {
  font-size: 1.4675rem;
}

.lokacija__component > .info > p {
  font-size: 1.2675rem;
}

.lokacija__dot {
  position: absolute;
  width: 0.75rem;
  aspect-ratio: 1/1;
  left: 0;
  top: 50%;
  transform: translate(-175%, -50%);
  border-radius: 50%;
}

.lokacija__dot#dot__1 {
  background-color: var(--clr-thm-2);
}

.lokacija__dot#dot__2 {
  background-color: var(--clr-thm-1);
}

.iframe__maps__container {
  margin-right: -15%;
  aspect-ratio: 1/1;
  width: min(21rem, 100%);
}

.iframe__maps__container > iframe {
  border-radius: 10%;
}

@media only screen and (max-width: 1100px) {
  .call__to__action {
    font-size: 2.5rem;
  }
}

@media only screen and (max-width: 840px) {
  .call__to__action {
    font-size: 1.9rem;
  }
}

@media only screen and (max-width: 1060px) {
  .hero__container#kontakt {
    padding-bottom: 5%;
  }
  .contact__content {
    flex-direction: column;
  }
  .mobitel__radno__vrijeme {
    width: 100%;
    gap: 2.5rem;
  }
  .lokacija {
    padding-top: 1.6375rem;
    width: 100%;
  }
  .mobitel__component > h1,
  .mobitel__component > p {
    font-size: 1.2675rem;
  }
  .mobitel__component > .phone__icon__container {
    width: 2.5rem;
    padding: 0.675rem;
  }
  .radno__vrijeme__component {
    padding: 1.675rem 0;
    gap: 1.3465rem;
  }
  .radno__vrijeme__component > h1 {
    font-size: 1.2675rem;
  }
  .radno__vrijeme__component > .info {
    font-size: 1rem;
    grid-row-gap: 0.675rem;
  }
  .radno__vrijeme__component > .clock__icon__container {
    width: 2.5rem;
    padding: 0.575rem;
  }
  .lokacija__component > .info {
    padding-left: 15%;
  }
  .lokacija__component > .info > h1 {
    font-size: 1.2575rem;
  }
  .lokacija__component > .info > p {
    font-size: 1.1265rem;
  }
  .iframe__maps__container {
    margin-right: 0;
  }
}
